<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">每日打卡配置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">打卡详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="打卡日期" class="searchboxItem ci-full">
              <span class="itemLabel">打卡日期:</span>
              <el-date-picker
                v-model="retrievalData.dateInfo"
                type="date"
                size="small"
                value-format="yyyy-MM-dd"
                value="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="bulkDownloadExaminationPaperList">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="打卡日期" align="left" show-overflow-tooltip prop="dateInfo" minWidth="150">
                <template slot-scope="scope">
                  {{
                    scope.row.dateInfo | momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="打卡人次" align="left" show-overflow-tooltip fixed prop="dailyPersonNum" minWidth="100px" />
              <el-table-column label="正确率" align="left" show-overflow-tooltip prop="accuracyStr" minWidth="100px" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "inkQuestionBank_dailyClockIn_dailyClockInInfo",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      // 打卡id
      exerciseId: "",
      // 检索数据
      retrievalData:{
        dateInfo:"", // 打卡日期
      }
    };
  },
  mixins: [List],
  created() {
    // 上个页面传过来的打卡id
    this.exerciseId = this.$route.query.exerciseId;
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        exerciseId: this.$route.query.exerciseId,
      };
      if(this.retrievalData.dateInfo){
        params.dateInfo = this.retrievalData.dateInfo;
      }
      this.doFetch({
        url: "/biz/exam/bank/daily/exercise/dailyDetailList",
        params,
        pageNum
      }, true, 6);
    },
    // 导出
    bulkDownloadExaminationPaperList() {
      const params = {
        exerciseId: this.$route.query.exerciseId,
      };
      if(this.retrievalData.dateInfo){
        params.dateInfo = this.retrievalData.dateInfo;
      }
      this.$post("/biz/exam/bank/daily/exercise/exportDailyDetail", params, 3000, true, 6)
        .then((res) => {
          window.open(res.data)
          // const arr = [...res.data];
          // for (let item of arr) {
          //   if (!this.downloadItems.includes(item.taskId)) {
          //     this.$store.dispatch("pushDownloadItems", item.taskId);
          //   } else {
          //     this.$message.warning(
          //       "[" + item.fileName + "]已经申请下载,请耐心等待"
          //     );
          //   }
          // }
        })
        .catch(() => {
          return;
        });
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 10;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
};
</script>

<style lang="less" scoped>
</style>